import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero"
import Preparatoria from "../components/preparatoria"
import Footer from "../components/footer"

const PreparatoriaPage = () => (
  <Layout>
    <Seo title="Preparatoria" />
    <Hero title="Asignaturas" subtitle="Preparatoria" type="PREPARATORIA" />
    <Preparatoria />
    <Footer />
  </Layout>
)

export default PreparatoriaPage
