import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"

const Preparatoria = () => {
  const data = useStaticQuery(graphql`
    query PreparatoriaQuery {
      allPreparatoriaJson {
        edges {
          node {
            id
            title
            description
            image {
              childImageSharp {
                gatsbyImageData(
                  transformOptions: { fit: COVER }
                  placeholder: BLURRED
                  webpOptions: { quality: 50 }
                )
              }
            }
          }
        }
      }
    }
  `)

  return (
    <PreparatoriaContainer>
      <PreparatoriaContent>
        {data.allPreparatoriaJson.edges.map(({ node }) => (
          <SubjectCard key={node.id}>
            <SubjectImage image={getImage(node.image)} alt={node.title} />
            <SubjectTitle>{node.title}</SubjectTitle>
            <SubjectDescription>{node.description}</SubjectDescription>
          </SubjectCard>
        ))}
      </PreparatoriaContent>
    </PreparatoriaContainer>
  )
}

export default Preparatoria

const PreparatoriaContainer = styled.section`
  display: flex;
  justify-content: center;
  margin: 110px 0;
`

const PreparatoriaContent = styled.div`
  max-width: 1200px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 25px 100px;
`

const SubjectCard = styled.div``

const SubjectImage = styled(GatsbyImage)`
  border-radius: 70px;
  margin-bottom: 20px;
  height: 400px;
`

const SubjectTitle = styled.h4`
  font-family: "Montserrat";
  color: #060f52;
  font-weight: 600;
  font-style: normal;
  font-size: 35px;
`

const SubjectDescription = styled.p`
  font-family: "Montserrat";
  color: #1a2050;
  font-weight: 300;
  font-style: normal;
  font-size: 18px;
`
